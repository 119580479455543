
const envBaseURL = process.env.VUE_APP_API;
const envImgUrl = process.env.VUE_APP_IMAGE_URL;

import KTLayoutSidebarImport from "@/assets/js/layout/extended/sidebar-import.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";

const closeOffcanvas = function () {
      new KTOffcanvas(KTLayoutSidebarImport.getElement()).hide();
};

const formatDate = function (date) {
        var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [year, month, day].join('-');
  };
      

const dataTransform = function(list, transform){
    // let list = item.list;
    // let transform = item.transform;

      if(list === null || list === undefined){
          return [];
        }

        list.forEach((item, ind) => { 

              
          
              for (let index = 0; index < transform.length; index++) {
                
                const ele = transform[index];

                //*************************************************************
                //***********************  type: condition ********************
                //*************************************************************

                if (ele["type"] === "condition")
                {
                      let mappingArr = ele["mapping"];

                      const mappingIndex /* [1] */ =  mappingArr.findIndex(object => {
                            return object.from /* [3] */ === item[ele["keyCondition"]] /* [4] */ ; // item[ele["keyCondition"]] เป็นค่าที่จะใช้ map
                      });

                      if (mappingIndex >= 0)
                      {
                        item[ele["keyResult"]] = mappingArr[mappingIndex]["to"];
                      } else {
                        item[ele["keyResult"]] =  ele["defaultTo"];
                      }
                } 

                //*************************************************************
                //***********************  type: link ********************
                //*************************************************************

                else if (ele["type"] === "link") {
                  item[ele["keyResult"]] =  (item[ele["useField"]] === null || item[ele["useField"]] === undefined || item[ele["useField"]] === "") ? /* เช็คก่อนถ้าเป็น null ใช้ default link */
                                                                                ele["defaultTo"] /* default link */
                                                                              : ele["mapUrl"] + ele["mapText"] + item[ele["useField"]] /* link + "/" + id */;

                }


                //*************************************************************
                //***********************  type: concat ********************
                //*************************************************************

                else if (ele["type"] === "concat") {

                  item[ele["useField1"]] = (item[ele["useField1"]] === null || item[ele["useField1"]] === undefined) ? "" : item[ele["useField1"]].toString();
                  item[ele["useField2"]] = (item[ele["useField2"]] === null || item[ele["useField2"]] === undefined) ? "" : item[ele["useField2"]].toString();
                  
                  ele["mapTextPrefix"] = (ele["mapTextPrefix"] === null || ele["mapTextPrefix"] === undefined) ? "" : ele["mapTextPrefix"];  
                  ele["mapTextBetween"] = (ele["mapTextBetween"] === null || ele["mapTextBetween"] === undefined) ? "" : ele["mapTextBetween"];  
                  ele["mapTextSuffix"] = (ele["mapTextSuffix"] === null || ele["mapTextSuffix"] === undefined) ? "" : ele["mapTextSuffix"];  
                 


                  item[ele["keyResult"]] =
                      ele["mapTextPrefix"]  
                    + item[ele["useField1"]]
                    + ele["mapTextBetween"] 
                    + item[ele["useField2"]]
                    + ele["mapTextSuffix"] 
                    ;/* link + "/" + id */

                  // console.log(" ele[keyResult] ", item[ele["keyResult"]]); 
                  

                }

                //*************************************************************
                //*************  type: concat2 ถ้าไม่มี ไม่ต้องแสดงเลย *************
                //*************************************************************

                else if (ele["type"] === "concat2") {

                        item[ele["useField1"]] = (item[ele["useField1"]] === null || item[ele["useField1"]] === undefined) ? "" : item[ele["useField1"]].toString();
                        item[ele["useField2"]] = (item[ele["useField2"]] === null || item[ele["useField2"]] === undefined) ? "" : item[ele["useField2"]].toString();
                        
                        ele["mapTextPrefix"] = (ele["mapTextPrefix"] === null || ele["mapTextPrefix"] === undefined) ? "" : ele["mapTextPrefix"];  
                        ele["mapTextBetween"] = (ele["mapTextBetween"] === null || ele["mapTextBetween"] === undefined) ? "" : ele["mapTextBetween"];  
                        ele["mapTextSuffix"] = (ele["mapTextSuffix"] === null || ele["mapTextSuffix"] === undefined) ? "" : ele["mapTextSuffix"];  
                      
                        if (item[ele["useField1"]] === null || item[ele["useField1"]] === undefined  || item[ele["useField1"]] === "") {
                          item[ele["keyResult"]] = null
                        } else {
                          
                          if (ele["field1Type"] === "datetime") {
                            item[ele["useField1"]] = formatDate(item[ele["useField1"]]);
                          }

                          if (ele["field2Type"] === "datetime") {
                            item[ele["useField2"]] = formatDate(item[ele["useField2"]]);
                          }

                            item[ele["keyResult"]] =
                            ele["mapTextPrefix"]  
                          + item[ele["useField1"]]
                          + ele["mapTextBetween"] 
                          + item[ele["useField2"]]
                          + ele["mapTextSuffix"] 
                          ;/* link + "/" + id */

                        // console.log(" ele[keyResult] ", item[ele["keyResult"]]); 
                        }

                 
                  

                }

                // console.log(" >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> "); 
                
                // console.log( "index : (", ind ,",",index , ") " , ele["keyResult"] ,  " :::::  item[ele[keyResult]] >>> ", item[ele["keyResult"]]);
                
                // console.log(" >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ");

                
              }
          
        });

        // console.log("%%%%%%%%%% transformList AFTER TRANSFORM >>> ",list);

        return list;
      
    };


export  {
  dataTransform,
  closeOffcanvas
};
