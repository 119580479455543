
import axios from 'axios'
import store from "@/core/services/store";
import Swal from 'sweetalert2';

import shapeFormat from '@/mixins/shapeFormat';

import { dataTransform } from './util.js';

const envBaseURL = process.env.VUE_APP_API;

const envImgUrl = process.env.VUE_APP_IMAGE_URL;

const envCouponUrl = process.env.VUE_APP_COUPON_URL;

const state = {
  itemId: "uid",
  searchParameters: {},
  transform:
    [
      {
        type: "condition",
        keyCondition: "couponStatus",
        keyResult: "statusColor",
        defaultTo: "warning",
        mapping: [
          { /* [3] */ from: "ว่างอยู่"  /* [4] : item[ele["keyCondition"]]   */, to: "success" },  /* [1] => index = 1 */
          { /* [3] */ from: "ส่งออก"  /* [4] : item[ele["keyCondition"]]   */, to: "warning" }, /* [1] => index = 2 */
          { /* [3] */ from: "ใช้แล้ว"  /* [4] : item[ele["keyCondition"]]   */, to: "dark" }, /* [1] => index = 2 */
          { /* [3] */ from: "หมดอายุ" /* [4] : item[ele["keyCondition"]]   */, to: "danger" }, /* [1] => index = 3 */
          { /* [3] */ from: "ยกเลิก" /* [4] : item[ele["keyCondition"]]   */, to: "info" }, /* [1] => index = 3 */
        ]
      },

      {
        type: "concat",
        keyResult: "staff",
        default: "ไม่มีข้อมูล",
        mapTextPrefix: "สร้างโดย: ",
        // mapTextBetween: "/",
        // mapTextSuffix: "/",
        useField1: "createBy",
        // useField2 : ""
      },
      {
        type: "concat",
        keyResult: "couponUrl",
        default: envCouponUrl,
        mapTextPrefix: envCouponUrl,
        useField1: "couponCode",
        // mapTextBetween: "/",
        // useField2 : "",
        // mapTextSuffix: "/",

      },
      {
        type: "concat2",
        keyResult: "expired",
        default: "",
        mapTextPrefix: "exp ",
        useField1: "expireDateTime",
        // mapTextBetween: "/",
        // useField2 : "",
        // mapTextSuffix: "/",

      },
      {
        type: "concat2",
        keyResult: "used",
        default: "",
        mapTextPrefix: "used ",
        useField1: "usedDateTime",
        field1Type: "datetime"
        // mapTextBetween: "/",
        // useField2 : "",
        // mapTextSuffix: "/",

      },
      {
        type: "concat2",
        keyResult: "createDate",
        default: "",
        mapTextPrefix: "",
        useField1: "createDateTime",
        field1Type: "datetime"
        // mapTextBetween: "/",
        // useField2 : "",
        // mapTextSuffix: "/",

      },
      {
        type: "concat2",
        keyResult: "access",
        default: "",
        mapTextPrefix: "เข้าถึง ",
        useField1: "accessCount",
        mapTextBetween: " ครั้ง",
        // useField2 : "",
        // mapTextSuffix: "/",

      },
    ]
  ,
  pageData: {},
  items: [
    // {
    //   uid: "0001",
    //   couponCode: "YB7FH9UT65",
    //   // description: "บริษัทเซ็นทรัล",
    //   customerName: "John Part",
    //   phone: "0979889878",
    //   email: "johnpar@admin.com",
    //   couponStatus: "ว่างอยู่",
    //   createDateTime: "2022-01-03",
    //   createBy: "admin1@lotus.com"

    // },
    // {
    //   uid: "0002",
    //   couponCode: "H7BF5KGHJ",
    //   customerName: "Poe et",
    //   phone: "0999999997",
    //   email: "poet.et@gmail.com",
    //   description: "บริษัท ใบชา จำกัด",
    //   couponStatus: "หมดอายุ",
    //   createDateTime: "2022-02-28",
    //   createBy: "admin2@lotus.com"

    // },
    // {
    //   uid: "0003",
    //   couponCode: "A8FYU6XT76",
    //   customerName: "Amber See",
    //   phone: "0876756321",
    //   email: "amber.see@gmail.com",
    //   // description: "โลตัส",
    //   couponStatus: "ส่งออก",
    //   accessCount: 34,
    //   expireDateTime: "2022-11-09",
    //   createDateTime: "2022-02-28",
    //   createBy: "admin1@lotus.com"
    // },
    // {
    //   id: "0004",
    //   couponCode: "ABFH3R5GV",
    //   customerName: "Ethan Chor",
    //   phone: "098989348",
    //   email: "ethan.chor@gmail.com",
    //   // description: "โลตัส",
    //   couponStatus: "ใช้แล้ว",
    //   createDateTime: "2022-02-28",
    //   createBy: "admin1@lotus.com"
    // }
  ], // end: data,
  selected: [],

  formData: {},

  stat: {
    available: 0,
    posted: 0,
    used: 0,
    canceled: 0
  }
};

const getters = {
  pageData: state => state.pageData,
  itemCount: state => state.itemCount,
  items: state => state.items,
  itemId: state => state.itemId,
  searchParameters: state => state.searchParameters,
  selected: state => state.selected,
  formData: state => state.formData,
  stat: state => state.stat,
};

const actions = {
  addDataUnshift({
    commit
  }, param) {

    let data = param;
    // let url = param.apiUrl;

    // // console.log("%%%%%%%%%%  store.state.common.baseUrl >>> ",store.state.common.baseUrl);
    // console.log("DATA UNSHIFT %%%%%%%%%%  url >>> ",url);
    // console.log("%%%%%%%%%%  data >>> ",fileData);

    // let submitRes = null;

    if (data.totalCoupon === null || data.totalCoupon === undefined) data.totalCoupon = 0;
    if (data.totalSms === null || data.totalSms === undefined) data.totalSms = 0;

    let responseText = `เพิ่มคูปองจำนวน ${data.totalCoupon} รายการ ส่ง sms จำนวน ${data.totalSms} รายการ`;

    return responseText;



  },

  async loadData({
    commit
  }, param) {

    let res = null;
    let list = [];

    let url = param.apiUrl;
    let data = param.params === null || param.params === undefined ? { page: 1, pageSize: 20 } : param.params;
    // let url = param.apiURL;
    // let data = param.params;
    // let form = param.formDetail

    // console.log("%%%%%%%%%% loadData url >>> ",url);
    // console.log("%%%%%%%%%% loadData data >>> ",data);
    // console.log("%%%%%%%%%% loadData data >>> ",data);

    // console.log("%%%%%%%%%% loadData BEFORE TRANSFORM >>> ");

    state.searchParameters = data;

    try {

      res = await axios.post(store.state.common.baseUrl + url, data, store.state.auth.apiHeader);
      // res = state.items;

      let item = { list: res.data.itemList, transform: state.transform };

      commit("TRANSFORM", item);
      commit("SET_ITEMCOUNT", res.data.pageData);
      commit("SET_STAT", res.data.stat);



      //  console.log("%%%%%%%%%% ENDDDDDD >>> ",ss);

    } catch (err) {
      Swal.fire({
        icon: 'warning',
        title: 'ไม่สามารถรับข้อมูลได้',
      });
    }


    return list;
  },

  //*** SUBMIT DATA FROM FORM

  async submitData({
    commit
  }, param) {

    let url = param.apiUrl;
    let data = param.params;
    let form = param.formDetail


    let res = null;

    try {
      res = await axios.post(store.state.common.baseUrl + url, data, store.state.auth.apiHeader);
      // res = state.items;


      commit("SET_FORM_DATA", data)

      if (form.nextAction === "redirect") {
        // redirectTo
        // window.location.href = form.redirectTo;
        router.push(form.redirectTo)
      }


      //  console.log("%%%%%%%%%% ENDDDDDD >>> ",ss);

    } catch (err) {
      Swal.fire({
        icon: 'warning',
        title: 'ไม่สามารถรับข้อมูลได้',
      });
    }
  },



  // *** [add-26May2022]
  async verifyCoupon({
    commit
  }, param) {

    let url = "/ecoupon-api/ecoupon/verify-coupon/".concat(param.code);
    let data = {};

    // console.log("STORE ACTION sendSms >>>> param ",param);

    // console.log("STORE ACTION sendSms state.selected; >>>> ",state.selected);

    let res = null;

    try {
      res = await axios.post(store.state.common.baseUrl + url, data, store.state.auth.apiHeader);



    } catch (err) {
      Swal.fire({
        icon: 'warning',
        title: 'ส่งข้อมูลไม่ได้',
      });
      return false;
    }
    return true;
  },

  // *** [add-26May2022]
  async genCoupon({
    commit
  }, param) {

    let url = param.apiUrl;

    let finalUrl = "";
    finalUrl = store.state.common.baseUrl + url;
    // let param = param.params;
    // let data = state.selected;

    Swal.fire({
      title: 'สร้างคูปอง',
      input: 'number',
      inputAttributes: {
        autocapitalize: 'off'
      },
      confirmButtonText: 'สร้าง',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      preConfirm: (input) => {
        return axios.post(finalUrl, { qty: input }, store.state.auth.apiHeader)
          .then(async (response) => {
            console.log("resp is ", response)
            if (response.status !== 200) {
              throw new Error(response.statusText)
            }

            let data = response.data;
            let responseText = `เพิ่มคูปองจำนวน ${data.totalCoupon} รายการ`;

            Swal.fire({
              title: "",
              text: responseText,
              icon: "success",
              confirmButtonClass: "btn btn-secondary"
            });

            if (param.nextAction === "callasync") {
              let params = {
                apiUrl: param.callApi,
                params: param.callParam,
              };
              await store.dispatch(param.callAction, params);
            }
          })
          .catch(error => {
            Swal.showValidationMessage(
              `Request failed: ${error}`
              // `ไม่สามารถสร้างคูปองได้`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    });



  },


  //*** SUBMIT sendSms with selected
  //*** 14June2022 */
  async sendSms({
    commit
  }, param) {

    let url = param.apiUrl;
    // let data = param.params;
    let dataParam = state.selected;

    // console.log("STORE ACTION sendSms >>>> param ",param);

    // console.log("STORE ACTION sendSms state.selected; >>>> ",state.selected);

    let res = null;

    try {
      res = await axios.post(store.state.common.baseUrl + url, dataParam, store.state.auth.apiHeader);
      let resData = res.data;
      // if (resData.totalCoupon === null || resData.totalCoupon === undefined) resData.totalCoupon = 0;
      if (resData.totalSms === null || resData.totalSms === undefined) resData.totalSms = 0;

      let responseText = `ส่ง sms จำนวน ${resData.totalSms} เครดิต`;
      let respMsg = "";
      let respIcon = "success";
      if (res.data.success === false) {
        respMsg = res.data.msg;
        respIcon = "warning";

      }
      Swal.fire({
        title: respMsg,
        text: responseText,
        icon: respIcon,
        confirmButtonClass: "btn btn-secondary"
      });

      if (param.nextAction === "callasync") {
        let params = {
          apiUrl: param.callApi,
          params: param.callParam,
        };

        await store.dispatch(param.callAction, params);
      }



    } catch (err) {
      Swal.fire({
        icon: 'warning',
        title: 'ส่งข้อมูลไม่ได้',
      });
    }
  },

  //*** SUBMIT sendSms with selected

  async sendSingleSms({
    commit
  }, param) {

    let url = param.apiUrl;
    // let param = param.params;
    // let data = state.selected;


    let res = null;

    // console.log("sendSingleSms >>> ",param);
    try {
      res = await axios.post(store.state.common.baseUrl + url + param.itemId, {}, store.state.auth.apiHeader);

      if (res.data.success === false) {
        Swal.fire({
          title: res.data.msg,
          text: "",
          icon: "warning",
        });
        return;
      }

      let data = res.data;
      if (data.totalCoupon === null || data.totalCoupon === undefined) data.totalCoupon = 0;
      if (data.totalSms === null || data.totalSms === undefined) data.totalSms = 0;

      let responseText = `ส่ง sms จำนวน ${data.totalSms} เครดิต`;


      if (param.nextAction === "callasync") {
        let params = {
          apiUrl: param.callApi,
          params: param.callParam,
        };
        Swal.fire({
          title: "",
          text: responseText,
          icon: "success",
          confirmButtonClass: "btn btn-secondary"
        });


        await store.dispatch(param.callAction, params);
      }



    } catch (err) {
      Swal.fire({
        icon: 'warning',
        title: 'ส่งข้อมูลไม่ได้',
      });
    }
  },



  //*** Cancel Coupon

  async cancelCoupon({
    commit
  }, param) {

    let url = "/ecoupon-api/ecoupon/cancel-coupon";
    // let data = param.params;
    let data = state.selected;
    let count = 0;
    if (state.selected) {
      count = state.selected.length;
    }


    let res = null;

    try {
      res = await axios.post(store.state.common.baseUrl + url, data, store.state.auth.apiHeader);
      // //*** ส่ง res.data กลับมาเป็น couponId */
      // await store.this.$store.dispatch("coupon/loadData", res.data);
      if (param.nextAction === "callasync") {

        // console.log("in call async  >>> ",param);
        let params = {
          apiUrl: param.callApi,
          params: param.callParam,
        };
        Swal.fire({
          title: "",
          text: "ทำการยกเลิกคูปองจำนวน ".concat(count.toString()).concat(" รายการ"),
          icon: "success",
          confirmButtonClass: "btn btn-secondary"
        });
        await store.dispatch(param.callAction, params);

        state.selected = [];

      }

    } catch (err) {
      Swal.fire({
        icon: 'warning',
        title: 'เลือกคูปองอย่างน้อย 1 รายการ',
      });
    }
  },

  //*** CHANGE TO USED

  async used({
    commit
  }, param) {

    // let url = "/ecoupon-api/ecoupon/coupon-used/".concat(param);
    let url = "/ecoupon-api/ecoupon/coupon-used";
    // let data = param.params;
    //  let form = param.formDetail

    // console.log("%%%%%%%%%% url >>> ",url);


    let res = null;
    let data = param;

    try {
      res = await axios.post(store.state.common.baseUrl + url, data, store.state.auth.apiHeader);
      // //*** ส่ง res.data กลับมาเป็น couponId */
      // await store.this.$store.dispatch("coupon/loadData", res.data);


    } catch (err) {
      Swal.fire({
        icon: 'warning',
        title: 'ไม่สามารถรับข้อมูลได้',
      });

      return false;
    }

    return true;
  },


  //*** CHANGE TO USED

  async staffClaim({
    commit
  }, param) {

    let url = "/ecoupon-api/ecoupon/staff-claim";

    let data = {
      brandId: param.params.campaignId,
      verificationCode: "",
      couponCode: param.itemId

    }


    // let data = param.params;
    //  let form = param.formDetail

    // console.log("%%%%%%%%%% data >>> ",data);


    let submitRes = null;

    try {
      Swal.fire({
        icon: 'warning',
        // title: 'ตรวจ',
        showCancelButton: true,
        text: `ต้องการทำการใช้งานคูปองหรือไม่`,
        confirmButtonText: 'ใช่ ต้องการใช้งานคูปอง',
      })
        .then(async (result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.value) {
            submitRes = await axios.post(
              store.state.common.baseUrl + url,
              data
              , store.state.auth.apiHeader
            );

            if (param.nextAction === "callasync") {

              console.log("&&&&&&&&&&& IN callasync");

              // let prms = {
              //   page: state.pageData.page,
              //   pageSize : state.pageData.pageSize,
              //   searchText: state.searchParameters.searchText === null || state.searchParameters.searchText === undefined ? "" : state.searchParameters.searchText,
              //   status: state.searchParameters.status === null || state.searchParameters.status === undefined ?"" : state.searchParameters.status
              // };
              let callParam = {
                apiUrl: param.callApi,
                params: state.searchParameters
              };

              //  console.log("&&&&&&&&&&& prms >>> ",prms);
              // console.log("&&&&&&&&&&& callParam >>> ",callParam);
              // console.log("&&&&&&&&&&& state.searchParameters.searchText >>> ",state.searchParameters.searchText)
              // console.log("&&&&&&&&&&& callParam.params >>> ",callParam.params)

              await store.dispatch(param.callAction, callParam);
            }
          }
        })
        ;

      store.commit("common/BUTTON_UNLOCK");



    } catch (err) {
      Swal.fire({
        icon: 'warning',
        title: 'ไม่สามารถรับข้อมูลได้',
      });
    }
  },



  //*** CHANGE TO USED

  async redeem({
    commit
  }, param) {

    let url = "/ecoupon-api/ecoupon/coupon-redeem";
    let data = param;
    //  let form = param.formDetail

    // console.log("%%%%%%%%%% url >>> ",url);


    let res = null;

    try {
      res = await axios.post(store.state.common.baseUrl + url, data, store.state.auth.apiHeader);
      // //*** ส่ง res.data กลับมาเป็น couponId */
      // await store.this.$store.dispatch("coupon/loadData", res.data);

      Swal.fire({
        title: "",
        text: "ใช้งานคูปองแล้ว",
        icon: "success",
        confirmButtonClass: "btn btn-secondary"
      });

      return res.data;



    } catch (err) {
      Swal.fire({
        icon: 'warning',
        title: 'รหัสตรวจสอบคูปองไม่ถูกต้อง',
        text: "โปรดติดต่อพนักงานร้านค้า"
      });
    }

    return null;
  },

  async export({
    commit
  }, param) {

    let url = "";
    let data = state.selected;
    let res = null;

    // try {
    //   res = await axios.post(store.state.common.baseUrl + url , {} , store.state.auth.apiHeader);
    //   // //*** ส่ง res.data กลับมาเป็น couponId */
    //   // await store.this.$store.dispatch("coupon/loadData", res.data);


    // } catch (err) {
    //    Swal.fire({
    //         icon: 'warning',
    //         title: 'ไม่สามารถรับข้อมูลได้',
    //       });
    // }

    return res.data;
  },



  //*** CHANGE TO USED

  async accessCoupon({
    commit
  }, param) {

    let url = "/ecoupon-api/ecoupon/update-coupon-access-count/".concat(param);
    // let data = param.params;
    //  let form = param.formDetail

    console.log("%%%%%%%%%% url >>> ", url);


    let res = null;

    try {
      res = await axios.post(store.state.common.baseUrl + url, {}, store.state.auth.apiHeader);
      // //*** ส่ง res.data กลับมาเป็น couponId */
      // await store.this.$store.dispatch("coupon/loadData", res.data);


    } catch (err) {
      Swal.fire({
        icon: 'warning',
        title: 'ไม่สามารถรับข้อมูลได้',
      });
    }

    return res.data;
  },





  add({
    commit
  }, item) {
    commit('ADD_ITEM', item)


  },

  clearParm({
    commit
  }) {

    commit('CLEAR_SEARCH_PARAM')
  },

  searchParm({
    commit
  }, item) {

    commit('ADD_SEARCH_PARAM', item)
  }
  ,

  addSelected({
    commit
  }, item) {
    commit('ADD_SELECTED', item);

    var selected = state.items

    var fil = selected.filter(x => x[state.itemId] === item[state.itemId]);

    if (fil.length > 0) return true;
    else return false;

  },

  selectedAll({
    commit
  }, list) {

    commit('SELECTED_All', list);
  },

  unSelectedAll({
    commit
  }, list) {

    commit('UNSELECTED_All', list);
  },

  clearAll({
    commit
  }) {

    commit('CLEAR_ALL');
  },


};

const mutations = {

  //*** BEGIN : LIST ACTION */

  TRANSFORM(state, item) {

    let list = item.list;
    let transform = item.transform;
    state.items = dataTransform(list, transform);

    // console.log("@@@@@@@@@@@@@@@@@@@ state.items @@@@@@@@@@@@ ", state.items);


  },

  TRANSFORM_UNSHIFT(state, item) {

    let list = item.list;
    let transform = item.transform;
    let newlist = dataTransform(list, transform);

    state.items.unshift(...newlist);

    // console.log("@@@@@@@@@@@@@@@@@@@ state.items @@@@@@@@@@@@ ", state.items);


  },

  TRANSFORM_PUSH(state, item) {

    let list = item.list;
    let transform = item.transform;
    let newlist = dataTransform(list, transform);

    state.items.push(...newlist);

    // console.log("@@@@@@@@@@@@@@@@@@@ state.items @@@@@@@@@@@@ ", state.items);


  },



  SET_FORM_DATA(state, item) {
    state.formData = item;
  },

  ADD_ITEM(state, item) {
    state.items.unshift(item);
    state.selected = [];
    state.selectedNumber = 0;

  },
  SET_STAT(state, items) {
    Object.assign(state.stat, items)
  },
  SET_ITEMS(state, items) {
    Object.assign(state.items, items)
  },
  SET_ITEMCOUNT(state, pageData) {
    // console.log("@@@@@@@@@@@@@ pageData >>> ",pageData);
    Object.assign(state.pageData, pageData)
  },
  UPDATE_PRODUCT(state, item) {
    const productIndex = state.items.findIndex((p) => p.id == item.id)
    Object.assign(state.items[productIndex], item)
  },
  REMOVE_IMAGE(state, item) {
    state.images.unshift(item)
  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.items.findIndex((p) => p.id == itemId)
    state.items.splice(ItemIndex, 1)
  },
  REMOVE_ALL(state) {
    state.items = [];
  },

  //*** END : LIST ACTION */

  APPLY_ALL(state, item) {
    state.items.forEach(element => {
      element[item.key] = item.value;
    });
  },
  ADD_SEARCH_PARAM(state, item) {
    Object.assign(state.searchParameters, item);
  },
  CLEAR_SEARCH_PARAM(state) {
    state.searchParameters = {};
  },

  ADD_SELECTED(state, item) {

    var fil = state.selected.filter(x => x[state.itemId] === item[state.itemId]);
    if (fil.length < 1) {
      state.selected.push(item);
    } else {
      const ItemIndex = state.selected.findIndex((p) => p[state.itemId] == item[state.itemId])
      state.selected.splice(ItemIndex, 1);
    }

    // console.log("selected >>> ",state.selected);
    // console.log("selected number >>> ",state.selected.length);

  },
  SELECTED_All(state) {
    let allItems = state.items;

    // if (list)
    if (state.selected.length < state.items.length) {
      state.selected = [];
      state.selected.push(...allItems);
    } else {
      state.selected = [];
    }

    // state.selected = [];
    // state.selected.push(...allItems);

    // console.log("@@@@@@@@@@@ SELECTED_ALL ",state.selected);

  },

  UNSELECTED_All(state) {
    state.selected = [];
    // let list = state.items;
    // var indList = [];
    // state.selected.forEach((ele, index) => {
    // var chk = list.filter(x => x[state.itemId] === ele[state.itemId]);
    // if (chk.length > 0) {
    //     indList.push(index);
    // }
    // });

    // for (let ind = indList.length - 1; ind >= 0; ind--) {
    // state.selected.splice(indList[ind], 1);

    // }

  },
  CLEAR_ALL(state) {
    state.selected = [];
    state.selectedNumber = 0;

  },

};

export default {
  isRegistered: false,
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
