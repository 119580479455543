import Vue from "vue";
import Router from "vue-router";
import store from "@/core/services/store";
import axios from 'axios';

// import { VERIFY_AUTH } from "@/core/services/store/auth.module";
// import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";

Vue.use(Router);
const router = new Router({
// export default new Router({
  mode: 'history',
  routes: [
    {
      path: "/",
      redirect: "/brand-detail",
      component: () => import("@/view/layout/Layout"),
      children: [
        // {
        //   path: "/dashboard",
        //   name: "dashboard",
        //   component: () => import("@/view/pages/eCouponDashboard.vue")
        // },

        // {
        //   path: "/brand-list",
        //   name: "admin-dashboard",
        //   component: () => import("@/view/pages/eCouponAdminBrandList.vue")
        // },

        // {
        //   path: "/brand-register",
        //   name: "admin-brand-register",
        //   component: () => import("@/view/pages/eCouponAdminBrandRegister.vue")
        // },
        // {
        //   path: "/registration-review",
        //   name: "admin-brand-register",
        //   component: () => import("@/view/pages/eCouponAdminRegistrationReview.vue")
        // },

         {
          path: "/brand-detail",
          name: "brand-detail",
          component: () => import("@/view/pages/eCouponAdminBrandDetail.vue")
        },

         {
          path: "/campaign-detail/:campaignId",
          name: "campaign-detail",
          component: () => import("@/view/pages/eCouponAdminCampaignDetail.vue")
        },

        {
          path: "/dashboard-register",
          name: "dashboard-register",
          component: () => import("@/view/pages/DashboardRegister.vue")
        },
        {
          path: "/dashboard-customer",
          name: "dashboard-customer",
          component: () => import("@/view/alex/DashboardCustomer.vue")
        },

        {
          path: "/c/:couponId",
          name: "mobile-coupon",
          component: () => import("@/view/pages/eCouponMobileCustomerQr.vue"),
          meta: {
            isAuthen: false
          }
        },
        {
          path: "/mobile-customer-info/:couponId",
          name: "mobile-coupon",
          component: () => import("@/view/pages/eCouponMobileCustomerInfo.vue"),
          meta: {
            isAuthen: false
          }
        },

        {
          path: "/mobile-scan-qr/:brandId/:verificationCode",
          name: "mobile-scan-qr",
          component: () => import("@/view/pages/eCouponScanQr.vue"),
          meta: {
            isAuthen: false
          }
        },

        {
          path: "/mobile-scan-success/:theCode",
          name: "mobile-scan-success",
          component: () => import("@/view/pages/eCouponScanQrSuccess.vue"),
          meta: {
            isAuthen: false
          }
        },
       
        
      ]
    },
   
    {
      path: "/",
      component: () => import("@/view/pages/auth/login_pages/Login-Brand.vue"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/login_pages/Login-Brand.vue")
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/Register.vue")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue"),
      meta: {
            isAuthen: false
      }
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  // Ensure we checked auth before each page load.
  // REPLACE BY BELL
  var tk = localStorage.getItem('accessToken');

 


  // console.log("%%%%%%%%%%%%% localStorage.getItem(accessToken)  ", tk);
  // console.log(" BEFORE %%%%%%%%%%%%% store.state.auth.apiHeader >>> ",store.state.auth.apiHeader);
  
 

  var authenticated = false;

  if (tk !== null && tk !== undefined && tk !== "") {
    
    var config = {
      headers: {
        'Authorization': "bearer " + tk
      }
    };

    store.commit("auth/SET_HEADER", tk);

    // console.log(" AFTER %%%%%%%%%%%%% store.state.auth.apiHeader >>> ",store.state.auth.apiHeader);


    var resp;
    var url = store.state.common.baseUrl + "/ecoupon-api/auth/check-authentication";
    try {
      resp = await axios.post(url, {}, config);
    } catch (err) {
      resp = null;
      // console.log("BEFORE ROOT >>>> " , url ,  " >>> ", resp);
    }

    
    if (resp !== null && resp !== undefined) {

      if (resp.status === 200) {
        // console.log("BEFORE ROOT >>>> STATUS 200", resp.data);

        authenticated = true;
        // var resauth = await store.dispatch('auth/getAuth');

        //   if (!resauth) {
        //     authenticated = false;
        //     // console.log("resauth ");
        //   }


        // } else {
        //   authenticated = false;
        // }

        // console.log("BEFORE ROOT >>>> DATA IS OK");

      } else {
        authenticated = false;
        // console.log("BEFORE ROOT >>>>STATUS NOT 200");
      }

    }
    else {
      authenticated = false;
    }
  } else {
    authenticated = false;
  }

  // console.log("TO.PATH ", to.path);
  // console.log("BEFORE ROOT authenticated is ***** >>>>> ", authenticated);
  
    if (to.meta.isAuthen === false) {
    // console.log("IS AUTHEN IS FALSE __--_-__-__--_--- to " , to)
     authenticated = true;
  }

  // *** next next next next next

  if (!authenticated && to.path !== '/login' ) {
    // console.log("1111111111111");
     return next({
      path: "/login"
      // save the location we were at to come back later
     });
    // return;
  }
  
  else if (!authenticated && to.path === '/login')
  {
    // console.log("2222222222");
    next();
    return ;
  }
  else {
    if (to.path === '/login') {
      return next({
      path: "/"
      // save the location we were at to come back later
     });
      
    }
    else {
      // console.log("THIS IS THE LAST NEXT() __--_-__-__--_---")
      next();
    }
    
  }

  // *** next next next next next

});

export default router
