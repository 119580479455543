
import axios from 'axios'
import store from "@/core/services/store";
import Swal from 'sweetalert2';

import shapeFormat from '@/mixins/shapeFormat';

import { dataTransform , closeOffcanvas } from './util.js';

const envBaseURL = process.env.VUE_APP_API;

const envImgUrl = process.env.VUE_APP_IMAGE_URL;

const state = {
  itemId : "uid",
  searchParameters: {},
  transform: 
    [
      

      {
        type: "concat",
        keyResult: "used",
        default: "ไม่มีข้อมูล",
        mapTextPrefix: "ใช้ไป: ",
        // mapTextBetween: "/",
        // mapTextSuffix: "<br>",
        useField1: "usedAmount"
        // useField2 : ""

      },
      {
        type: "concat",
        keyResult: "total",
        default: "ไม่มีข้อมูล",
        mapTextPrefix: "ทั้งหมด: ",
        // mapTextBetween: "/",
        // mapTextSuffix: "/",
        useField1: "totalAmount"
        // useField2 : ""
      },

      {
        type: "link",
        keyResult: "campaignlink",
        defaultTo: "/",
        mapUrl: "/campaign-detail",
        mapText: "/",
        useField: "uid",

      },
    ]
  ,
  items: [
              // {
              //   uid: "0001",
              //   image: "/media/svg/avatars/001-boy.svg",
              //   name: "Central NP",
              //   description: "บริษัทเซ็นทรัล",
                
              //   status: "active",
              //   startDateTime: "2022-01-03",
              //   endDateTime: "2022-06-30",
                          
              //   totalAmount: 1000,
              //   usedAmount: 300,
                
               
              // },
              // {
              //   uid: "0002",
              //   image: "/media/svg/avatars/047-girl-25.svg",
              //   name: "ใบชา",
              //   description: "บริษัท ใบชา จำกัด",
              //   status: "active",
              //   startDateTime: "2022-02-28",
              //   endDateTime: "2022-09-30",

              //   totalAmount: 500,
              //   usedAmount: 99,
                
              // },
              // {
              //   uid: "0003",
              //   image: "/media/svg/avatars/001-boy.svg",
              //   name: "โลตัส",
              //   description: "โลตัส",
              //   status: "inactive",
              //   startDateTime: "2022-02-01",
              //   endDateTime: "2022-08-30",

              //   totalAmount: 800,
              //   usedAmount: 97,
              // },
            
    ], // end: data,
    selected: [],
    formData: {},
};

const getters = {
    items: state => state.items,
    itemId: state => state.itemId,
    searchParameters: state => state.searchParameters,
    selected: state => state.selected,
    formData: state => state.formData
};

const actions = {

  async loadData({
    commit
  },param) {

    let res = null;
    let list = [];

    let url = param.apiUrl;
    let data = param.params === null || param.params === undefined ? {} :  param.params ;
    // console.log("%%%%%%%%%% loadData param >>> ",param);
    // console.log("%%%%%%%%%% store.state.auth.apiHeader >>> ",store.state.auth.apiHeader);

    try {

      res = await axios.post(store.state.common.baseUrl + url, data, store.state.auth.apiHeader);
      // res = await axios.post(store.state.apiURL + params.apiURL, params.params, store.state.auth.apiHeader);
      // res = state.items;

      let item = {list:  res.data, transform:  state.transform};
    
      commit("TRANSFORM", item);


      //  console.log("%%%%%%%%%% ENDDDDDD >>> ",ss);

    } catch (err) {
       Swal.fire({
            icon: 'warning',
            title: 'ไม่สามารถรับข้อมูลได้',
          });
    }

    
    return list;
  },


  //*** SUBMIT DATA FROM FORM

  async submitData({
    commit
  }, param) {

    let url = param.apiUrl;
    let data = param.params;
    let form = param.formDetail

    // console.log("%%%%%%%%%% url>>> ",url);

    // console.log("%%%%%%%%%%data >>> ",data);

    // console.log("%%%%%%%%%% form >>> ",form );

    let submitRes = null;

    try {
      submitRes = await axios.post(
          store.state.common.baseUrl + url ,
          data
          , store.state.auth.apiHeader
        );
      // res = state.items;

      closeOffcanvas();
      
      if (form.nextAction === "callasync") {

          let params = {
            apiUrl: form.callApi,
            params: form.callParam,
          };
          await store.dispatch(form.callAction,params);
          // redirectTo
          // window.location.href = form.redirectTo;
          // router.push(form.redirectTo)
        }

      //  console.log("%%%%%%%%%% ENDDDDDD >>> ",submitRes.data);

    } catch (err) {
       Swal.fire({
            icon: 'warning',
            title: 'ไม่สามารถรับข้อมูลได้',
       });
       store.commit("common/BUTTON_UNLOCK");
    }

    commit("CLEAR_FORM");

    store.commit("common/BUTTON_UNLOCK");

    

    
    // return list;
  },

  //*** LOAD DATA FROM FORM
  
  async loadFormData({
    commit
  },param) {

    let url = param.apiUrl;
    let data = param.params;
    
    // console.log("%%%%%%%%%% param.apiUrl>>> ",url);

    // console.log("%%%%%%%%%% param.params>>> ",data);

    let submitRes = null;

    try {
        

        submitRes = await axios.post(
          store.state.common.baseUrl + url + data ,
          {},
           store.state.auth.apiHeader
          // ,
          // this.$store.state.auth.apiHeader
        );
        
      console.log("%%%%%%%%%% SET_FORM_DATA >>> ",submitRes.data);
        
      
        commit("SET_FORM_DATA", submitRes.data);


          store.commit("common/BUTTON_UNLOCK");


      } catch (err) {
        // console.log("err >>> ",err.response.data);
       Swal.fire({
            icon: 'warning',
            title: 'ไม่สามารถรับข้อมูลได้',
            text: err.response === null ? "" : err.response.data.detail
       }
         
       );
      store.commit("common/BUTTON_UNLOCK");
    }

      // try {
      // res = await axios.post(store.state.apiUrl + params.apiUrl, params.params, store.state.auth.apiHeader);


      


      //  console.log("%%%%%%%%%% ENDDDDDD >>> ",ss);

      // } catch (err) {
      //    Swal.fire({
      //         icon: 'warning',
      //         title: 'ไม่สามารถรับข้อมูลได้',
      //       });
    // }

  },


  async updateData({
    commit
  },param) {

    let url = param.apiUrl;
    let data = param.params;
    let dataId = param.dataId;
    
    // console.log("%%%%%%%%%% param.apiUrl>>> ",url);

    // console.log("%%%%%%%%%% param.params>>> ",data);

    let submitRes = null;

    try {
        

        submitRes = await axios.post(
          store.state.common.baseUrl + url + dataId ,
          data
         , store.state.auth.apiHeader
        );
        
      
      
      store.commit("common/BUTTON_UNLOCK");

      Swal.fire({
        icon: 'success',
        title: 'บันทึกเรียบร้อยแล้ว',
        confirmButtonText: 'OK',
      });
       


      } catch (err) {
        // console.log("err >>> ",err.response.data);
       Swal.fire({
            icon: 'warning',
            title: 'ไม่สามารถบันทึกข้อมูลได้',
            text: err.response === null ? "" : err.response.data.detail
       }
       );
       store.commit("common/BUTTON_UNLOCK");
    }

      // try {
      // res = await axios.post(store.state.apiUrl + params.apiUrl, params.params, store.state.auth.apiHeader);


      


      //  console.log("%%%%%%%%%% ENDDDDDD >>> ",ss);

      // } catch (err) {
      //    Swal.fire({
      //         icon: 'warning',
      //         title: 'ไม่สามารถรับข้อมูลได้',
      //       });
    // }

  },

  add({
    commit
  }, item) {
    commit('ADD_ITEM', item)


  },

  clearParm({
    commit
  }) {

    commit('CLEAR_SEARCH_PARAM')
  },

  searchParm({
    commit
  }, item) {

    commit('ADD_SEARCH_PARAM', item)
  }
  ,

  addSelected({
    commit
  }, item) {
    commit('ADD_SELECTED', item);

    var selected = state.items

    var fil = selected.filter(x => x[state.itemId] === item[state.itemId]);

    if (fil.length > 0) return true;
    else return false;

  },

  selectedAll({
    commit
  }, list) {

    commit('SELECTED_All', list);
  },

  unSelectedAll({
    commit
  }, list) {

    commit('UNSELECTED_All', list);
  },

  clearAll({
    commit
  }) {

    commit('CLEAR_ALL');
  },

    
};

const mutations = {

    //*** BEGIN : LIST ACTION */

    TRANSFORM(state, item) {

    let list = item.list;
    let transform = item.transform;
    state.items =  dataTransform(list, transform);      
    },

    TRANSFORM_UNSHIFT(state, item) {

    let list = item.list;
    let transform = item.transform;
    let newlist =  dataTransform(list, transform);   

    state.items.unshift(...newlist);
    
    // console.log("@@@@@@@@@@@@@@@@@@@ state.items @@@@@@@@@@@@ ", state.items);
    

    },

    SET_FORM_DATA(state, item) {
       state.formData = item;
    },

  CLEAR_FORM(state) {
      state.formData = {};
    },

    ADD_ITEM(state, item) {
        state.items.unshift(item)
    },
    SET_ITEMS(state, items) {
        Object.assign(state.items, items)
    },
    UPDATE_PRODUCT(state, item) {
        const productIndex = state.items.findIndex((p) => p.id == item.id)
        Object.assign(state.items[productIndex], item)
    },
    REMOVE_IMAGE(state, item) {
        state.images.unshift(item)
    },
    REMOVE_ITEM(state, itemId) {
        const ItemIndex = state.items.findIndex((p) => p.id == itemId)
        state.items.splice(ItemIndex, 1)
    },
    REMOVE_ALL(state) {
        state.items = [];
    },

    //*** END : LIST ACTION */

    APPLY_ALL(state, item) {
        state.items.forEach(element => {
        element[item.key] = item.value;
        });
    },
    ADD_SEARCH_PARAM(state, item) {
        Object.assign(state.searchParameters, item);
    },
    CLEAR_SEARCH_PARAM(state) {
        state.searchParameters = {};
    },

     ADD_SELECTED(state, item) {

        var fil = state.selected.filter(x => x[state.itemId] === item[state.itemId]);
        if (fil.length < 1) {
            state.selected.push(item);
        } else {
          const ItemIndex = state.selected.findIndex((p) => p[state.itemId] == item[state.itemId])
          state.selected.splice(ItemIndex, 1);
        }

        // console.log("selected >>> ",state.selected);
        // console.log("selected number >>> ",state.selected.length);

    },
  SELECTED_All(state) {
        let allItems = state.items;

    // if (list)
      if (state.selected.length < state.items.length) {
             state.selected = [];
              state.selected.push(...allItems);
      } else {
         state.selected = [];
      }
    
        // state.selected = [];
        // state.selected.push(...allItems);

        // console.log("@@@@@@@@@@@ SELECTED_ALL ",state.selected);

    },

  UNSELECTED_All(state) {
        state.selected = [];
        // let list = state.items;
        // var indList = [];
        // state.selected.forEach((ele, index) => {
        // var chk = list.filter(x => x[state.itemId] === ele[state.itemId]);
        // if (chk.length > 0) {
        //     indList.push(index);
        // }
        // });

        // for (let ind = indList.length - 1; ind >= 0; ind--) {
        // state.selected.splice(indList[ind], 1);

        // }

    },

    CLEAR_ALL(state) {
        state.selected = [];
        state.selectedNumber = 0;

    },

};

export default {
  isRegistered: false,
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
