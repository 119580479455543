
import axios from 'axios'
import store from "@/core/services/store";


const envBaseURL = process.env.VUE_APP_API;
const envImgUrl = process.env.VUE_APP_IMAGE_URL;
const envCouponUrl = process.env.VUE_APP_COUPON_URL;

const state = {
   qrUrl: "https://wtg.promo",
   baseUrl: envBaseURL,
  imageUrl: envImgUrl,
  couponUrl : envCouponUrl,
   overlay: false,
  buttonLock: false,
   defaultImage: "/media/0mine/defaultImage.png",
   maxImageSize : 2,
   
};

const getters = {
  qrUrl: state => state.qrUrl,
  baseUrl: state => state.baseUrl,
  imageUrl: state => state.imageUrl,
  couponUrl: state => state.couponUrl,
  overlay: state => state.overlay,
  buttonLock: state => state.buttonLock,
  maxImageSize: state => state.maxImageSize,
  // auth() {
  //   return state.auth;
  // },
  // currentUser(state) {
  //   return state.user;
  // },
  // isAuthenticated(state) {
  //   return state.isAuthenticated;
  // }
};

const actions = {
  // [LOGIN](context, credentials) {
  //   return new Promise(resolve => {
  //     ApiService.post("login", credentials)
  //       .then(({ data }) => {
  //         // console.log("Here what post returns", data);
  //         context.commit(SET_AUTH, data);
  //         resolve(data);
  //       })
  //       .catch(({ response }) => {
  //         context.commit(SET_ERROR, response.data.errors);
  //       });
  //   });
  // },

  // [GOOGLE_LOGIN](context, credentials) {
  //   return new Promise(resolve => {
  //     ApiService.post("http://localhost:8000/gauth/xxx", credentials)
  //       .then(({ data }) => {
  //         console.log("Here what post returns", data);
  //         // context.commit(SET_AUTH, data);
  //         resolve(data);
  //       })
  //       .catch(({ response }) => {
  //         console.log("ERROR >>> ",response);
  //         // context.commit(SET_ERROR, response.data.errors);
  //       });
  //   });
  // },

  // [LOGOUT](context) {
  //   context.commit(PURGE_AUTH);
  //   window.location.href = "/login";
  // },
  // [REGISTER](context, credentials) {
  //   return new Promise(resolve => {
  //     ApiService.post("login", credentials)
  //       .then(({ data }) => {
  //         context.commit(SET_AUTH, data);
  //         resolve(data);
  //       })
  //       .catch(({ response }) => {
  //         context.commit(SET_ERROR, response.data.errors);
  //       });
  //   });
  // },
  
};

const mutations = {

  BUTTON_LOCK(state) {
    state.buttonLock = true;
  },

  BUTTON_UNLOCK(state) {
    state.buttonLock = false;
  },

  OVERLAY(state, param) {
    state.overlay = param;
  }
  // [UPDATE_AUTH](state, new_auth) {
  //   // console.log("mutations/UPDATE_AUTH ", new_auth);
  //   state.auth = new_auth;
  // },
  // [UPDATE_TOKEN](state) {

  //   var tk = localStorage.getItem("id_token");
  //   // var tkpaser = JSON.parse(tk);
  //   state.apiHeader = {
  //     headers: {
  //       Authorization: "bearer " + tk
  //     }
  //   };
  //   console.log( state.apiHeader );
  // },
  // [SET_HEADER](state, tk) {
  //   state.apiHeader = {
  //     headers: {
  //       Authorization: "bearer " + tk
  //     }
  //   }
  //   // state.errors = error;
  // },
  // [SET_ERROR](state, error) {
  //   state.errors = error;
  // },
  // [SET_AUTH](state, user) {
  //   state.isAuthenticated = true;
  //   state.user = user;
  //   state.errors = {};
  //   JwtService.saveToken(state.user.token);
  //   console.log("SET_AUTH >>> JwtService.saveToken");
  // },
  // [SET_PASSWORD](state, password) {
  //   state.user.password = password;
  // },
  // [PURGE_AUTH](state) {
  //   state.isAuthenticated = false;
  //   state.user = {};
  //   state.errors = {};
  //   JwtService.destroyToken();
  //    console.log("PURGE_AUTH >>> JwtService.destroyToken");
  // }
};

// export default {
//   state,
//   actions,
//   mutations,
//   getters
// };

export default {
  isRegistered: false,
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
