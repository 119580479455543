
import axios from 'axios'
import store from "@/core/services/store";
import Swal from 'sweetalert2';
import { dataTransform } from './util.js';


const envBaseURL = process.env.VUE_APP_API;
const envImgUrl = process.env.VUE_APP_IMAGE_URL;

const state = {
  itemId : "uid",
  searchParameters: {},
  transform: 
    [
      {
        type: "condition",
        keyCondition: "text9",
        keyResult: "text10",
        defaultTo: "warning",
        mapping: [
          { /* [3] */ from:"ว่างอยู่"  /* [4] : item[ele["keyCondition"]]   */, to: "success"},  /* [1] => index = 1 */
          { /* [3] */ from:"ใช้แล้ว"  /* [4] : item[ele["keyCondition"]]   */ , to:"dark"}, /* [1] => index = 2 */
           { /* [3] */ from:"หมดอายุ" /* [4] : item[ele["keyCondition"]]   */ , to: "danger"}, /* [1] => index = 3 */
            
        ]
      },
       {
        type: "concat",
         keyResult: "remain",
         default: "ไม่มีข้อมูล",
        mapTextPrefix: "/",
        mapTextBetween: "/",
        mapTextSuffix: "/",
        useField1: "",
        useField2 : ""

      },
      {
        type: "link",
        keyResult: "brandlink",
        defaultTo: "https://youtube.com",
        mapUrl: "https://youtube.com",
        mapText: "/",
        useField: "uid",

      },
    ]
  ,
  items: [
              {
                uid: "0001",
                text0: "media/svg/avatars/001-boy.svg",
                text1: "Brad Simmons",
                text2: "HTML, JS, ReactJS",
                text3: "Intertico",
                text4: "Web, UI/UX Design",
                text5: "30%",
                text6: "danger"

                ,
                text7: "ใช้ไป 300",
                text8: "ทั้งหมด 1000",
                text9: "ว่างอยู่",
                text10: "success",
                text13: "2022-12-05",
                text14: "format: en",
                text15: "en"
              },
              {
                uid: "0002",
                text0: "media/svg/avatars/018-girl-9.svg",
                text1: "Jessie Clarcson",
                text2: "C#, ASP.NET, MS SQL",
                text3: "Agoda",
                text4: "Houses & Hotels",
                text5: "40%",
                text6: "success"
                ,
                text7: "ใช้ไป 400",
                text8: "ทั้งหมด 1000",
                text9: "ใช้แล้ว",
                text10: "dark",
                text11: "เข้าถึง: 1 ครั้ง",
                text12: "ใช้คูปอง: 03/09/2022",
                text13: "2022-02-01",
                text14: "format: th",
                text15: "th"
                
              },
              {
                uid: "0003",
                text0: "media/svg/avatars/047-girl-25.svg",
                text1: "Lebron Wayde",
                text2: "PHP, Laravel, VueJS",
                text3: "RoadGee",
                text4: "Transportation",
                text5: "40%",
                text6: "primary"
                ,
                text7: "ใช้ไป 800",
                text8: "ทั้งหมด 2000",
                text9: "หมดอายุ",
                text10: "primary",
                text11: "เข้าถึง: 4 ครั้ง",
                text13: "2022-09-01",
                text14: "format: slash",
                text15: "slash"
              },
              {
                uid: "0004",
                text0: "media/svg/avatars/014-girl-7.svg",
                text1: "Natali Trump",
                text2: "Python, PostgreSQL, ReactJS",
                text3: "The Hill",
                text4: "Insurance",
                text5: "90%",
                text6: "danger"
                ,
                text7: "ใช้ไป 900",
                text8: "ทั้งหมด 1000",
                text9: "ส่งออก",
                text10: "warning",
                text11: "เข้าถึง: 1 ครั้ง",
                text13: "2022-03-15",
                text14: "format : no input"
              }
    ], // end: data,
    selected: [],
    selectedNumber: 0,
    formData: {},
};

const getters = {
    items: state => state.items,
    itemId: state => state.itemId,
    searchParameters: state => state.searchParameters,
    selected: state => state.selected,
    selectedNumber: state => () => {
        return state.selected.length;
  },
    
    formData: state => state.formData
};

const actions = {


  async loadData({
    commit
  }, params)  {

    let res = null;
    let list = [];

    // console.log("%%%%%%%%%% loadData BEFORE TRANSFORM >>> ");

    // try {
      // res = await axios.post(store.state.apiURL + params.apiURL, params.params, store.state.auth.apiHeader);
      // res = state.items;

    console.log("mytable.module >>> params ",params);

      let item = {list:  state.items, transform:  state.transform};
    
      let ss = commit("TRANSFORM", item);


      //  console.log("%%%%%%%%%% ENDDDDDD >>> ",ss);

    // } catch (err) {
    //    Swal.fire({
    //         icon: 'warning',
    //         title: 'ไม่สามารถรับข้อมูลได้',
    //       });
    // }

    
    return list;
  },


  //*** SUBMIT DATA FROM FORM
 
  async loadFormData({
    commit
  },param) {

    let url = param.apiURL;
    let data = param.params;

      // try {
      // res = await axios.post(store.state.apiURL + params.apiURL, params.params, store.state.auth.apiHeader);
      res = state.formData;

      commit("ADD_DATA_TO_FORM",res )

      


      //  console.log("%%%%%%%%%% ENDDDDDD >>> ",ss);

      // } catch (err) {
      //    Swal.fire({
      //         icon: 'warning',
      //         title: 'ไม่สามารถรับข้อมูลได้',
      //       });
    // }

  },


 //*** SUBMIT DATA FROM FORM
  
  async submitData({
    commit
  }, param) {

    let url = param.apiURL;
    let data = param.params;
    let form = param.formDetail

    // console.log("%%%%%%%%%% param.apiURL>>> ",url);

    // console.log("%%%%%%%%%% param.params>>> ",data);

    commit("SET_FORM_DATA",data )

    if (form.nextAction === "redirect") {
      // redirectTo
      // window.location.href = form.redirectTo;
      router.push(form.redirectTo)
    }

    // try {
      // res = await axios.post(store.state.apiURL + params.apiURL, params.params, store.state.auth.apiHeader);
      // res = state.items;

      


      //  console.log("%%%%%%%%%% ENDDDDDD >>> ",ss);

    // } catch (err) {
    //    Swal.fire({
    //         icon: 'warning',
    //         title: 'ไม่สามารถรับข้อมูลได้',
    //       });
    // }

    
  },


  add({
    commit
  }, item) {
    commit('ADD_ITEM', item)


  },

  clearParm({
    commit
  }) {

    commit('CLEAR_SEARCH_PARAM')
  },

  searchParm({
    commit
  }, item) {

    commit('ADD_SEARCH_PARAM', item)
  }
  ,

  addSelected({
    commit
  }, item) {

    
    

    commit('ADD_SELECTED', item);

    // var selected = state.items
    // var fil = selected.filter(x => x[state.itemId] === item[state.itemId]);

    // if (fil.length > 0) return true;
    // else return false;



  },

  selectedAll({
    commit
  }, list) {

    commit('SELECTED_All', list);
  },

  unSelectedAll({
    commit
  }, list) {

    commit('UNSELECTED_All', list);
  },

  clearAll({
    commit
  }) {

    commit('CLEAR_ALL');
  },

    
};

const mutations = {

    //*** BEGIN : LIST ACTION */

    TRANSFORM(state, item) {

    let list = item.list;
    let transform = item.transform;
    state.items =  dataTransform(list, transform);
      
    },

    ADD_ITEM(state, item) {
        state.items.unshift(item)
    },

    ADD_DATA_TO_FORM(state, item) {
       state.formData = item;
  },
    
    SET_ITEMS(state, items) {
        Object.assign(state.items, items)
    },
    UPDATE_PRODUCT(state, item) {
        const productIndex = state.items.findIndex((p) => p.id == item.id)
        Object.assign(state.items[productIndex], item)
    },
    REMOVE_IMAGE(state, item) {
        state.images.unshift(item)
    },
    REMOVE_ITEM(state, itemId) {
        const ItemIndex = state.items.findIndex((p) => p.id == itemId)
        state.items.splice(ItemIndex, 1)
    },
    REMOVE_ALL(state) {
        state.items = [];
    },

    //*** END : LIST ACTION */

    APPLY_ALL(state, item) {
        state.items.forEach(element => {
        element[item.key] = item.value;
        });
    },
    ADD_SEARCH_PARAM(state, item) {
        Object.assign(state.searchParameters, item);
    },
    CLEAR_SEARCH_PARAM(state) {
        state.searchParameters = {};
    },

    ADD_SELECTED(state, item) {

        var fil = state.selected.filter(x => x[state.itemId] === item[state.itemId]);
        if (fil.length < 1) {
            state.selected.push(item);
        } else {
          const ItemIndex = state.selected.findIndex((p) => p[state.itemId] == item[state.itemId])
          state.selected.splice(ItemIndex, 1);
        }

        // console.log("selected >>> ",state.selected);
        // console.log("selected number >>> ",state.selected.length);

    },
  SELECTED_All(state) {
        let allItems = state.items;

    // if (list)
      if (state.selected.length < state.items.length) {
             state.selected = [];
              state.selected.push(...allItems);
      } else {
         state.selected = [];
      }
    
        // state.selected = [];
        // state.selected.push(...allItems);

        // console.log("@@@@@@@@@@@ SELECTED_ALL ",state.selected);

    },

  UNSELECTED_All(state) {
        state.selected = [];
        // let list = state.items;
        // var indList = [];
        // state.selected.forEach((ele, index) => {
        // var chk = list.filter(x => x[state.itemId] === ele[state.itemId]);
        // if (chk.length > 0) {
        //     indList.push(index);
        // }
        // });

        // for (let ind = indList.length - 1; ind >= 0; ind--) {
        // state.selected.splice(indList[ind], 1);

        // }

    },

    CLEAR_ALL(state) {
        state.selected = [];
        state.selectedNumber = 0;

    },

};

export default {
  isRegistered: false,
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
