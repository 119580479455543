//********************************************************************************************************
//********************************************************************************************************
//************** TAMMY IS  HERE SHE BLESS YOU
//******************************************************************************************************** 
//******************************************************************************************************** 

import Vue from "vue";
import Vuex from "vuex";




import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import menu from "./menu.module";
import common from "./common.module";
import shop from "./shop.module";
import table from "./mytable.module";
import brand from "./brand.module";
import campaign from "./campaign.module";
import coupon from "./coupon.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    menu,
    common,
    shop,
    table,brand,campaign,coupon
  }
});
