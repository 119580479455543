
import axios from 'axios'
import store from "@/core/services/store";
import Swal from 'sweetalert2';
import router from '@/router';

import shapeFormat from '@/mixins/shapeFormat';

import { dataTransform } from './util.js';

const envBaseURL = process.env.VUE_APP_API;

const envImgUrl = process.env.VUE_APP_IMAGE_URL;

const state = {
  itemId : "uid",
  searchParameters: {},
  transform: 
    [
      {
        type: "condition",
        keyResult: "statusColor",
        keyCondition: "status",
        defaultTo: "dark",
        mapping: [
          { /* [3] */ from:"active"  /* [4] : item[ele["keyCondition"]]   */, to: "success"},  /* [1] => index = 1 */
          { /* [3] */ from:"inactive"  /* [4] : item[ele["keyCondition"]]   */ , to:"dark"}, /* [1] => index = 2 */
          // { /* [3] */ from:"0002" /* [4] : item[ele["keyCondition"]]   */ , to: "warning"}, /* [1] => index = 3 */
            
        ]
      },

      {
        type: "link",
        keyResult: "brandlink",
        defaultTo: "/",
        mapUrl: "/brand-detail",
        mapText: "/",
        useField: "uid",

      },
    ]
  ,
  items: [
              // {
              //   uid: "0001",
              //   name: "Central NP",
              //   description: "บริษัทเซ็นทรัล",
              //   status: "active",
              //   createDateTime: "2022-01-03",
               
              // },
              // {
              //   uid: "0002",
              //   name: "ใบชา",
              //   description: "บริษัท ใบชา จำกัด",
              //   status: "active",
              //   createDateTime: "2022-02-28",
                
              // },
              // {
              //   uid: "0003",
              //   name: "โลตัส",
              //   description: "โลตัส",
              //   status: "inactive",
              //   createDateTime: "2022-02-28",
              // },
              // {
              //   uid: "0004",
              //   name: "แมคโดดัล",
              //   description: "แมคโดดัล ไทยแลนด์",
              //   status: "inactive",
              //   createDateTime: "2022-02-28",
              // }
    ], // end: data,
    selected: [],

  formData: {
        // username: "admin@example.com",
        // password: "1234567890",
        // name: "Senjo shabu",
        // description: "Senjo shabu",
        // credit: 0,
        // packageSize: "s"
    },
};

const getters = {
    items: state => state.items,
    itemId: state => state.itemId,
    searchParameters: state => state.searchParameters,
    selected: state => state.selected,
    formData: state => state.formData
};

const actions = {

  async loadData({
    commit
  },param) {

    let res = null;
    let list = [];

    let url = param.apiUrl;
    let data = param.params;
    // let form = param.formDetail

    // console.log("%%%%%%%%%% brand.loadData >>> ",data);


    try {
      res = await axios.post(store.state.common.baseUrl + url, data, store.state.auth.apiHeader );
      // res = state.items;


      // console.log("%%%%%%%%%% brand.loadData  res.data >>> ",res.data);


     let item = {list:  res.data, transform:  state.transform};
     commit("TRANSFORM", item);
    

      //  console.log("%%%%%%%%%% loadData ENDDDDDD >>> ",state.items);

    } catch (err) {
      
      //  Swal.fire({
      //       icon: 'warning',
      //       title: 'ไม่สามารถรับข้อมูลได้',
      //     });
    }

    
    return list;
  },

  //*** LOAD DATA FROM FORM
  
  async loadFormData({
    commit
  },param) {

    let url = param.apiUrl;
    let data = param.params;
    
    // console.log("%%%%%%%%%% param.apiUrl>>> ",url);

    // console.log("%%%%%%%%%% param.params>>> ",data);

    let submitRes = null;

    let finalUrl = "";
    if (data === null || data === undefined || data === "")
    {
      finalUrl = store.state.common.baseUrl + url;
    }
    else {
      finalUrl = store.state.common.baseUrl + url + data;
    }

    try {
        

        submitRes = await axios.post(
          finalUrl ,
          {}
          , store.state.auth.apiHeader
        );
        
      // console.log("%%%%%%%%%% SET_FORM_DATA >>> ",submitRes.data);
        
      
        commit("SET_FORM_DATA", submitRes.data);


       


      } catch (err) {
        // console.log("err >>> ",err.response.data);
       Swal.fire({
            icon: 'warning',
            title: 'ไม่สามารถบันทึกข้อมูลได้',
            text: err.response === null ? "" : err.response.data.detail
       }
       );
    }


  },

  //***  เฉพาะ brand staff
  async loadSingle({
    commit
  },param) {

    let url = "/ecoupon-api/ecoupon/shop/load-single-brand";
    let data = param;
    let finalUrl  = ""
    finalUrl = store.state.common.baseUrl + url ; 
    
    // console.log("%%%%%%%%%% param.apiUrl>>> ",url);

    // console.log("%%%%%%%%%% param.params>>> ",data);

    let submitRes = null;

    try {
        

        submitRes = await axios.post(
          finalUrl ,
          {}
          , store.state.auth.apiHeader
        );
        
      // console.log("%%%%%%%%%% SET_FORM_DATA >>> ",submitRes.data);
        
      
        commit("SET_FORM_DATA", submitRes.data);


       


      } catch (err) {
        // console.log("err >>> ",err.response.data);
       Swal.fire({
            icon: 'warning',
            title: 'ไม่สามารถบันทึกข้อมูลได้',
            text: err.response === null ? "" : err.response.data.detail
       }
       );
    }
    
    return submitRes.data;

  },

  async addCredit({
    commit
  }, param) {

    let url = param.apiUrl;
    // let param = param.params;
    // let data = state.selected;

    Swal.fire({
      title: 'เพิ่มคูปองเครดิต',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Add',
      showLoaderOnConfirm: true,
      preConfirm: (login) => {
        return fetch(`//api.github.com/users/${login}`)
          .then(response => {
            if (!response.ok) {
              throw new Error(response.statusText)
            }
            return response.json()
          })
          .catch(error => {
            Swal.showValidationMessage(
              `Request failed: ${error}`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    });

   
    // let res = null;

    // try {
    //   res = await axios.post(store.state.common.baseUrl + url + param.itemId, {}, store.state.auth.apiHeader);

      

    //     if (param.nextAction === "callasync") {
    //       let params = {
    //         apiUrl: param.callApi,
    //         params: param.callParam,
    //       };
    //       await store.dispatch(param.callAction,params);
    //     }



    // } catch (err) {
    //    Swal.fire({
    //         icon: 'warning',
    //         title: 'ส่งข้อมูลไม่ได้',
    //       });
    // }
  },

  async updateData({
    commit
  },param) {

    let url = param.apiUrl;
    let data = param.params;
    // let dataId = param.dataId;
    
    // console.log("%%%%%%%%%% param.apiUrl>>> ",url);

    // console.log("%%%%%%%%%% param.params>>> ",data);

    let submitRes = null;

    try {
        

        submitRes = await axios.post(
          store.state.common.baseUrl + url  ,
          data
         , store.state.auth.apiHeader
        );
        
        // console.log("%%%%%%%%%% SET_FORM_DATA >>> ",submitRes.data);
        
      
        // commit("SET_FORM_DATA", submitRes.data);
      
      store.commit("common/BUTTON_UNLOCK");

      Swal.fire({
        icon: 'success',
        title: 'บันทึกเรียบร้อยแล้ว',
        confirmButtonText: 'OK',
      });
       


      } catch (err) {
        // console.log("err >>> ",err.response.data);
       Swal.fire({
            icon: 'warning',
            title: 'ไม่สามารถบันทึกข้อมูลได้',
            text: err.response === null ? "" : err.response.data.detail
       }
       );
       store.commit("common/BUTTON_UNLOCK");
    }

      // try {
      // res = await axios.post(store.state.apiUrl + params.apiUrl, params.params, store.state.auth.apiHeader);


      


      //  console.log("%%%%%%%%%% ENDDDDDD >>> ",ss);

      // } catch (err) {
      //    Swal.fire({
      //         icon: 'warning',
      //         title: 'ไม่สามารถรับข้อมูลได้',
      //       });
    // }

  },

  //*** REVIEW DATA FROM FORM
  
  async reviewData({
    commit
  }, param) {

    // let url = param.apiUrl;
    let data = param.params;
    let form = param.formDetail

    // console.log("%%%%%%%%%% param.apiUrl>>> ",url);

    // console.log("%%%%%%%%%% param.params>>> ",data);

    commit("SET_FORM_DATA",data )

    if (form.nextAction === "redirect") {
      // redirectTo
      // window.location.href = form.redirectTo;
      router.push(form.redirectTo)
    }

    // try {
      // res = await axios.post(store.state.apiUrl + params.apiUrl, params.params, store.state.auth.apiHeader);
      // res = state.items;

      


      //  console.log("%%%%%%%%%% ENDDDDDD >>> ",ss);

    // } catch (err) {
    //    Swal.fire({
    //         icon: 'warning',
    //         title: 'ไม่สามารถรับข้อมูลได้',
    //       });
    // }

    
  },

  //*** SUBMIT DATA FROM FORM
  
  async submitData({
    commit
  }, param) {

    let url = param.apiUrl;
    let data = param.params;
    let form = param.formDetail

    // console.log("%%%%%%%%%% param.apiUrl>>> ",url);

    // console.log("%%%%%%%%%% param.params>>> ",data);

    let submitRes = null;

      try {

        submitRes = await axios.post(
          store.state.common.baseUrl + url ,
          data
          , store.state.auth.apiHeader
        );

        commit("SET_FORM_DATA",data )

       
          Swal.fire({
            icon: 'success',
            title: 'บันทึกเรียบร้อยแล้ว',
            confirmButtonText: 'OK',
         })
        .then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                 if (form.nextAction === "redirect") {
                      // redirectTo
                      // window.location.href = form.redirectTo;
                      router.push(form.redirectTo)
                    }
              } 
            })
        ;


      } catch (err) {
        // console.log("err >>> ",err.response.data);
       Swal.fire({
            icon: 'warning',
            title: 'ไม่สามารถบันทึกข้อมูลได้',
            text: err.response.data === null ? "" : err.response.data.detail
       }
       );
    }

    
    // try {
      // res = await axios.post(store.state.apiUrl + params.apiUrl, params.params, store.state.auth.apiHeader);
      // res = state.items;

      

      


      //  console.log("%%%%%%%%%% ENDDDDDD >>> ",ss);

    // } catch (err) {
    //    Swal.fire({
    //         icon: 'warning',
    //         title: 'ไม่สามารถรับข้อมูลได้',
    //       });
    // }

    
  },




  add({
    commit
  }, item) {
    commit('ADD_ITEM', item)


  },

  clearParm({
    commit
  }) {

    commit('CLEAR_SEARCH_PARAM')
  },

  searchParm({
    commit
  }, item) {

    commit('ADD_SEARCH_PARAM', item)
  }
  ,

  addSelected({
    commit
  }, item) {
    commit('ADD_SELECTED', item);

    var selected = state.items

    var fil = selected.filter(x => x[state.itemId] === item[state.itemId]);

    if (fil.length > 0) return true;
    else return false;

  },

  selectedAll({
    commit
  }, list) {

    commit('SELECTED_All', list);
  },

  unSelectedAll({
    commit
  }, list) {

    commit('UNSELECTED_All', list);
  },

  clearAll({
    commit
  }) {

    commit('CLEAR_ALL');
  },

    
};

const mutations = {

    //*** BEGIN : LIST ACTION */

    TRANSFORM(state, item) {

    let list = item.list;
    let transform = item.transform;
    state.items =  dataTransform(list, transform);      
    },

    TRANSFORM_UNSHIFT(state, item) {

    let list = item.list;
    let transform = item.transform;
    let newlist =  dataTransform(list, transform);   

    state.items.unshift(...newlist);
    
    // console.log("@@@@@@@@@@@@@@@@@@@ state.items @@@@@@@@@@@@ ", state.items);
    

    },

     SET_FORM_DATA(state, item) {
       state.formData = item;
       console.log("@@@@@@@@ SET_FORM_DATA @@@@@@@@@@@ state.formData @@@@@@@@@@@@ ", state.formData);
    },

    ADD_ITEM(state, item) {
        state.items.unshift(item)
    },
    SET_ITEMS(state, items) {
        Object.assign(state.items, items)
    },
    UPDATE_PRODUCT(state, item) {
        const productIndex = state.items.findIndex((p) => p.id == item.id)
        Object.assign(state.items[productIndex], item)
    },
    REMOVE_IMAGE(state, item) {
        state.images.unshift(item)
    },
    REMOVE_ITEM(state, itemId) {
        const ItemIndex = state.items.findIndex((p) => p.id == itemId)
        state.items.splice(ItemIndex, 1)
    },
    REMOVE_ALL(state) {
        state.items = [];
    },

    //*** END : LIST ACTION */

    APPLY_ALL(state, item) {
        state.items.forEach(element => {
        element[item.key] = item.value;
        });
    },
    ADD_SEARCH_PARAM(state, item) {
        Object.assign(state.searchParameters, item);
    },
    CLEAR_SEARCH_PARAM(state) {
        state.searchParameters = {};
    },

     ADD_SELECTED(state, item) {

        var fil = state.selected.filter(x => x[state.itemId] === item[state.itemId]);
        if (fil.length < 1) {
            state.selected.push(item);
        } else {
          const ItemIndex = state.selected.findIndex((p) => p[state.itemId] == item[state.itemId])
          state.selected.splice(ItemIndex, 1);
        }

        // console.log("selected >>> ",state.selected);
        // console.log("selected number >>> ",state.selected.length);

    },
  SELECTED_All(state) {
        let allItems = state.items;

    // if (list)
      if (state.selected.length < state.items.length) {
             state.selected = [];
              state.selected.push(...allItems);
      } else {
         state.selected = [];
      }
    
        // state.selected = [];
        // state.selected.push(...allItems);

        // console.log("@@@@@@@@@@@ SELECTED_ALL ",state.selected);

    },

  UNSELECTED_All(state) {
        state.selected = [];
        // let list = state.items;
        // var indList = [];
        // state.selected.forEach((ele, index) => {
        // var chk = list.filter(x => x[state.itemId] === ele[state.itemId]);
        // if (chk.length > 0) {
        //     indList.push(index);
        // }
        // });

        // for (let ind = indList.length - 1; ind >= 0; ind--) {
        // state.selected.splice(indList[ind], 1);

        // }

    },
    CLEAR_ALL(state) {
        state.selected = [];
        state.selectedNumber = 0;

    },

};

export default {
  isRegistered: false,
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
